import axios from 'axios';
import {LoadLocalStorage} from "./app/utils/Utilities";

var url = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
    baseURL: url
});


console.log("Connected to: ", url);
console.log("Company: " +(
    LoadLocalStorage('selectedCompany')?.id ?
        `${LoadLocalStorage('selectedCompany')?.id} - ${LoadLocalStorage('selectedCompany')?.name}` :
        "Without selected company")
);

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['CompanyId'] = LoadLocalStorage('selectedCompany')?.id ?? null;
        return config;
    },
    (error) => Promise.reject(error)
);


axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Erro a obter os dados da API!')
);

export default axiosInstance;
